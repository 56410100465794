html {
  scroll-padding-top: 150px;
}


a.c-sidebar-nav-link:hover {
  /*  background-color: #6c6c6c !important;  */
  /*  background-color: #686868 !important;  */
   background-color:   #6868686b !important; 
 
   border-radius: 15px;

  /* background-color:  #f0720a !important; */
 
}



.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-active {
  color: white;
  /*background: #f0720a;*/
  background:  #0070f2;
 
  border-radius: 15px;
}




ol.border-0.c-subheader-nav.m-0.px-0.px-md-3.breadcrumb {
  margin-top: -10px !important;
}


.badge {

  font-size: 14px;
  font-weight: 400;
}


.dropdown-item.active, .dropdown-item:active {
  text-decoration: none !important;
  color: #fff;
  background-color: #ebedef;
}

.dropdown-item.hover, .dropdown-item:hover {
  color: #455164;
  text-decoration: none !important;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: #3c4b64;
}
li.c-sidebar-nav-dropdown {
  width: inherit;
  background-color: #ff8646eb;
  margin: 5px;
  border-radius: 15px;
}


a.btn.btn-lg.btn-link {
  color: #000;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  margin: 0;
  text-decoration: none;
  background-color: #ced2d8!important;
  border: #ced2d8;
  padding: .375rem .75rem;
  font-size: .875rem;
}

a.btn.btn-lg.btn-primary{
  color: #000;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
  margin: 0;
 
  background-color: #ced2d8!important;
  border: #ced2d8;
  padding: .375rem .75rem;
  font-size: .875rem;
}




.swal-footer {
 text-align:center;
  padding-top: 0px;
  margin-top: 0px;

  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.MuiFormGroup-root {
  display: block !important;
  flex-wrap: wrap;
  flex-direction: column;
}

.MuiTypography-body1 {
 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;;
  font-size: 0.875rem !important;;
  font-weight: 400 !important;;
  line-height: 1.5 !important;;
}
.notification #circle {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: red;
  position: absolute;
  height: 15px;
  width: 15px;
  top: 8px;
  right: -24px;
}

.viewpage h3 {
  margin-top: 15px;
  margin-bottom: 20px;
}

.notification {
 
  padding: 1px 10px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

span.badge.badge1.badge2 {
  right: 30px;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: red;
  color: white;
  position: absolute;
 
}

.notification .badge1 {

  background-color: #ced2d8;
  color: black;
}


.position-relative.table-responsive {
  overflow-x: visible !important;
}


.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 1rem;
  height: 1rem !important;
  font-size: 1rem;
}

html:not([dir=rtl]) .c-sidebar-nav-icon:first-child {
  margin-left: -1.5rem;
  color:black;
}

svg.c-icon.c-icon-custom-size.text-danger.mb-2 {
  margin: 0px 5px 0px 0px !important;
  width: 15px;
}

table div.my-5.text-center h2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.swal-title {
  white-space: pre-wrap;
  padding: 0px;
}


.messageview { 
  padding: 1px 15px 0px 15px;}
  
  .mt-4.c-messages.col-lg-4, .mt-4.c-message.col-lg-8{
  overflow: scroll;
      max-height: 70vh;}
    
      .mt-4.c-messages.col-lg-4::-webkit-scrollbar, .mt-4.c-message.col-lg-8::-webkit-scrollbar {
      width: 0.2em;
  }
   
  .mt-4.c-messages.col-lg-4::-webkit-scrollbar-track, .mt-4.c-message.col-lg-8::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
   
  .mt-4.c-messages.col-lg-4::-webkit-scrollbar-thumb, .mt-4.c-message.col-lg-8::-webkit-scrollbar-thumb {
    background-color: #ff8646eb;
    outline: 1px solid #ff8646eb;
  }


  

header.card-header.card-header.bg-light {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.mr-auto.text-center.c-header-nav {
  justify-content: center;
  margin: 0px auto;
}


.position-relative.table-responsive {
  overflow-x: hidden;
}

.swal-icon:first-child {
  margin-top: 10px;
}

.swal-title {

  font-size: 14px;
}

.sweet-alert {
  width: 478px !important;
}

.sweet-alert p {
  flex-direction: row-reverse;
  
}

.sweet-alert div {
  margin: 0px auto !important;
  background-size: 70px 70px !important;
  margin-top: -10px !important;
}

.sweet-alert h2 {
  /* display: none; */
  font-size: 14px !important;
color: rgba(0, 0, 0, 0.65);
}

.w3-border-blue, .w3-hover-border-blue:hover {
  border-color: #2196F3!important;
}
.w3-pale-blue, .w3-hover-pale-blue:hover {
  color: #000!important;
  background-color: white!important;
}

.w3-leftbar {
  border-left: 6px solid #ccc!important;
}
.w3-container, .w3-panel {
  padding-left: 10px;
    padding-top: 10px;
}

.swal-modal {
  padding-left: 5px;
    padding-right: 5px;}


    {/*}



.swal-icon {
  margin-left: 10px;
  top: 60px;
}
*/}

.swal-icon {
 
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 10px auto;
}

button.btn.btn-secondary.text-black.btn {
  min-width: 80px;
}

.swal-button {
 
  color: black;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 400;
 
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
  background-color: #ced2d8 !important;
  border-color: #ced2d8;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}


.swal-icon--custom {
  width: 15%;
  height: 15%;
  max-width: 100%;
  border: none;
  border-radius: 0;
  text-align: left;
}


.badge-primary:first-letter {
 
  text-transform: uppercase;
}

.badge-primary {

  text-transform: lowercase;
}


.badge-success:first-letter {
  color: #fff;
  background-color: #2eb85c;
  text-transform: uppercase;
}

.badge-success {
  color: #fff;
  background-color: #2eb85c;
  text-transform: lowercase;
}

.badge-secondary:first-letter  {
  color: #4f5d73;
  background-color: #ced2d891;
  text-transform: uppercase;
}

.badge-secondary {
  color: #4f5d73;
  background-color: #ced2d891;
  text-transform: lowercase;
}



.badge-warning:first-letter  {
  color: #4f5d73;
  background-color: #f9b115;
  text-transform: uppercase;
}

.badge-warning{
  color: #4f5d73;
  background-color: #f9b115;
  text-transform: lowercase;
}

.badge-danger:first-letter  {
  color: #fff;
  background-color: #e55353;
  text-transform: uppercase;
}

.badge-danger {
  color: #fff;
  background-color: #e55353;
  text-transform: lowercase;
}


.viewpage {
  padding-top: 0rem !important;
}

.viewpage1 {
  padding-bottom: 0rem !important;
}

.CDataTable_arrow-position__2VWUO {
  right: auto !important;
  top: 50%;
  transform: translateY(-50%);
}

.CDataTable_arrow-position__-bp-G{
  right: auto !important;
  top: 50%;
  transform: translateY(-50%);
}


.c-sidebar .c-sidebar-minimizer {
  background-color: white;
  border-top: 1px solid #d8dbe0;
}






.c-sidebar .c-hover.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-sidebar-nav-link.c-hover {
  color: white;
  background:#f0720a;
  border-radius: 15px;
}

.c-sidebar .c-sidebar-brand, .c-sidebar .c-sidebar-header {
  background: #f7f7f9!important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d8dbe0;
  margin-left: 5px;
  /* border-radius: 10px; */
}

.c-body {
  background: white !important;
}

ul.c-sidebar-nav.h-100.ps {
 /* border: 2px solid #848687;*/
  margin-top: 0px !important;
 /* border-radius: 10px;*/
  margin-left: 5px;
  background-color: #f7f7f9;
 
 /* background-color: transparent;*/
}





.badge-secondary {
  color: #4f5d73;
  background-color: #ced2d891;
}


.c-footer {
  color: #4f5d73;
  background: white !important;
  border-top: 1px solid #d8dbe0;
}

header.c-header.c-header-light.c-header-fixed.c-header-with-subheader {
  /*border: 2px solid #848687;
  border-radius: 10px; */
  margin-left: 0px;
  margin-right: 4px;
}

main.c-main {
  /* border: 2px solid #848687;
  border-radius: 10px; */
  margin-top: 5px !important;
  margin-left: 5px;
  margin-right: 4px;
}



.action{
  color: #ff8646 !important;
      background-color: white !important;
      border-color: #ced2d8 !important;
  }


.badge-info {
  color: #fff;
  background-color: #0086e6;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #0086e6;
}

a:hover .c-sidebar-nav-link {
  background-color: pink;
}

a:hover {
  color: #0086e6;
}

.c-app {
  background-color: white;
}



.c-sidebar-nav-item {
  width: inherit;
  /*background-color: #ff8646eb;*/
  margin: 5px;
  border-radius: 15px;
}

.text-primary {
  color: #0086e6 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0086e6;
  border-color: #0086e6;
}

ul.c-sidebar-nav.h-100.ps {
  margin-top: 30px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 1px solid;
  color: #0086e6;
  background-color: #fff;
  border-color: #d8dbe0;
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: black;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  border-radius: 15px;
}

.c-sidebar {
  color: #fff;
  background: white;
}


tr.table-sm {
  display: none;
}

.c-header .c-subheader {
  margin-top: -1px;
  border-top: 1px solid #d8dbe0;
 
}


button.my-2.my-sm-0.btn.btn-outline-success {
  padding: 3px 5px 3px 5px !important;
  font-size: 0.8rem !important;
}


.btn .c-icon, .btn i {
  height: .7rem !important;
  margin: .21875rem 0;
}

button.cil-options.dropdown-toggle.btn.btn-secondary {
  padding: 2px 5px 2px 5px;
}

.table th, .table td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f0f3f5;
  opacity: 1;
}

.bg-secondary {
  background-color: #ced2d8 !important;
  color: black  !important;
}

.btn-secondary {
  color: black;
  background-color: #ced2d8;
  border-color: #ced2d8;
}

.btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: black !important;
  background-color: #ced2d8 !important;
  border-color: #ced2d8 !important;
}

.btn-primary {
  color: black !important;
  background-color: #ced2d8 !important;
  border-color: #ced2d8 !important;
}


.alert-danger {
  color: #f44336;
  background-color: transparent;
  border-color: transparent;
}

.alert {
  position: relative;
  padding: 0rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}


.alert-success {
  color: black;
  background-color: #ced2d8;
  border-color: #ced2d8;
}



.c-body {
  background: #ebedef;
}



.card { margin-bottom: 0.8rem !important; }

.card-header i {
  font-size: 90%;
  /* font-weight: 600; */
  color: #97999a;
}

.card-po {
  background: #A4C639 !important;
border: 1px solid #90ab3d !important;
}

.card-msg {
  background: chocolate;
  border: 1px solid #b15a1c !important;
}

.text-value a {
  color: #000;
}

.text-right.my-4.text-value-lg {
  margin: 5px !important;
}

.card-body.row.text-center {
  /* margin: 5px !important; */
  padding: 5px !important;
}

.card-header {
  background-color: #f0f3f5;
  border-color: #c8ced3;
}

i.c-icon.c-icon-3xl.mb-2.cib-read-the-docs, i.c-icon.c-icon-3xl.mb-2.cil-description, i.c-icon.c-icon-3xl.mb-2.cil-credit-card {
  width: 100% !important;
text-align: right;
}

.card-header {
  background: #f0f3f5 !important;
  font-weight: bolder;
  color: black;
}

/* messages */

.c-message-headers-from a {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
}

.c-message-headers-from {
  font-style: italic;
  text-decoration: dashed;
}
.btn.bg-gradient-info, .btn-success{
color:#000 !important;
}
.text-black{
color:#000 !important;
}
.text-dark {
  color: black!important;
}

.align-center.alert.alert-danger h5 {
  font-size: 14px;
}

.c-main {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  padding-top: 10px !important;
}



@media (min-width: 768px) {

.c-main>.container-fluid, .c-main>.container-lg, .c-main>.container-md, .c-main>.container-sm, .c-main>.container-xl, .c-main>.container-xxl {
  padding-right: 10px;
  padding-left: 10px;
}
}

.btn.bg-gradient-info, .btn-success {
  color: white !important;
}



.form-control1 {
width:50px !important;
display:inline-block !important;}


